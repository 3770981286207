import idb from 'logic/idb';

let outboxCount = 0;

async function syncOutbox(items) {
  const res = await batchWrite(items).catch((err) => elog(err.message, { label: 'sync:8' }));
  if (!res) return;
  for (const id of res) {
    idb.delete('sync', id);
    console.log(id);
  }
  console.warn('batchedWrite success');
  return true;
}
export default async function sync() {
  const outbox = await idb.getAll('sync', null, null, 10);
  if (outbox.length > outboxCount) {
    console.warn('SYNC-------------------');
    console.log(`count: outbox ${outbox.length} > state outboxCount ${outboxCount}`);
    outboxCount = outbox.length;
    const res = await syncOutbox(outbox);
    if (!res) return console.error('syncOutbox error');
    sync();
  }
}

import { store } from 'helpers/components';

import sync from 'logic/sync';
import idb from 'logic/idb';

export const UPDATE_IDB_STORE = 'UPDATE_IDB_STORE';

// deprecated???
export async function updateStore(storeName, data) {
  console.table(data);
  console.log(data.dtEdited ? 'edited' : 'inserted');
  await Promise.all([idb.add([data], 'sync'), idb.add([data], storeName)]);

  store.dispatch('idb', { type: UPDATE_IDB_STORE, store: { [storeName]: Date.now() } });
  sync();
}

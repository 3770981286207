import idb from 'logic/idb';
import timely from 'logic/timely';

export const controller = new AbortController();

let auth;
/**
 *
 * @param path
 * @param options
 * Implement:
 * post: data
 * put: data
 * delete: data
 * check if data is formData if not JSON
 * if JSON add headers like below
 * this is a helper function
 */
export async function _fetch(path, options = {}) {
  if (!navigator.onLine) {
    const error = new Error('offline');
    error.name = 'Offline';
    throw error;
  }

  let { method, body, jwt = null } = options;

  const isJSON = body && !(body instanceof FormData) ? true : false;

  const opts = {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'same-origin',
    signal: controller.signal,
  };

  if (jwt) {
    const token = await getToken();
    opts.headers.Authorization = 'Bearer ' + token;
  }

  if (method) {
    opts.method = method;
    opts.body = isJSON ? JSON.stringify(body) : body;
  }

  const res = await request(path, opts);
  return res;
}
async function getToken() {
  if (auth && Date.now() < auth.expireAt * 1000) {
    return auth.jwt;
  } else {
    auth = await idb.get('auth', 'settings');
    if (!auth || Date.now() > auth.expireAt * 1000) {
      $('app-signin').showLogin();
      console.log(auth);
      console.log('token', timely(auth.expireAt * 1000).format('DateTime'))
      console.log('now', timely(Date.now()).format('DateTime'));
      auth = null;
      throw new Error(401);
    }
    return auth.jwt;
  }
}
async function request(path, opts) {
  const res = await fetch(path, opts);
  if (res.status === 200 && res.status < 300) {
    return result(res);
  } else if (res.status == 401) {
    console.log(await result(res));
    $('app-signin').showLogin();
    auth = null;
    throw new Error(401);
  } else if (res.status == 403) {
    throw 403;
  } else if (res.status == 404) {
    throw 'Page not found';
  } else if (res.status == 500) {
    throw 'Internal server error';
  } else {
    const err = await result(res);
    throw err.error || err;
  }
}
function result(res) {
  const contentType = res.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    return res.json();
  } else {
    return res.text();
  }
}
